import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import { MetadataReadonly } from "../types/contentfulTypes"
import { themes } from "../components/styles/ColorStyles"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const Privacy = (props: Props) => {
  const { data } = props

  return (
    <Layout {...data}>
      <Wrapper>
        <Content>
          <h1>Privacy Policy</h1>
          <p>
            <em>Last updated: July 23, 2019</em>
          </p>
          <p>
            <strong>Shadowness Inc.</strong> ('us', 'we', or 'our') operates the{" "}
            <a href="https://angle.sh" aria-label="AngleHome">
              https://angle.sh
            </a>{" "}
            website (the 'Service').
          </p>
          <h2>Personal Information Collect, Use, Disclosure and Consent</h2>
          <p>
            Personal Information may include your name, email address, age, phone number, education
            and employment information.
          </p>
          <p>
            We are committed to providing our clients, customers, members ('you', 'your' or 'them')
            with exceptional service. Providing exceptional service may involve the collection, use
            and, at times, the disclosure of your Personal Information. Protecting your Personal
            Information is one of our highest priorities. While we have always respected your
            privacy and safeguarded all Personal Information, we have strengthened our commitment to
            this goal. This is to continue to provide exceptional service to you and to comply with
            all laws regarding the collection, use and disclosure of Personal Information. We will
            inform you of why and how we collect, use and disclose Personal Information obtain your
            consent, as required and handle Personal Information according to applicable law. Our
            privacy commitment includes ensuring the accuracy, confidentiality, and security of your
            Personal Information and allowing you to request access to, and correction of, your
            personal information.
          </p>
          <p>
            This page informs you of our policies regarding the collection, use and disclosure of
            Personal Information when you use our Service.
          </p>
          <p>
            We will not use or share your information with anyone except as described in this
            Privacy Policy.
          </p>
          <p>
            We will only collect Personal Information that is necessary to fulfill the following
            purposes:
          </p>
          <p>To verify identity;</p>
          <p>To identify your preferences;</p>
          <p>To open and manage an account;</p>
          <p>To ensure you receive a high standard of service;</p>
          <p>To meet regulatory requirements;</p>
          <p>Other legal reasons as apply to the goods and services requested.</p>
          <p>
            We will obtain your consent to collect, use or disclose Personal Information. In some
            cases, we can do so without your consent (see below). You can provide consent orally, in
            writing, electronically or through an authorized representative.
          </p>
          <p>
            You provide implied consent where our purpose for collecting using or disclosing your
            Personal Information would be considered obvious or reasonable in the circumstances.
            Consent may also be implied where you have notice and a reasonable opportunity to
            opt-out of having your Personal Information used for mail-outs, marketing or fundraising
            and you do not opt-out.
          </p>
          <p>We may collect, use or disclose Personal Information without the consent:</p>
          <p>When permitted or required by law;</p>
          <p>In an emergency that threatens an individual's life, health, or personal security;</p>
          <p>When the Personal Information is available from a public source;</p>
          <p>When we require legal advice from a lawyer;</p>
          <p>For the purposes of collecting a debt or protection from fraud;</p>
          <p>Other legally established reasons.</p>
          <p>
            We will not sell your Personal Information to other parties unless consent has been
            provided or implied. We retain your Personal Information for the time necessary to
            fulfill the identified purposes or a legal or business purpose. We will make reasonable
            efforts to ensure that your Personal Information is accurate and complete. You may
            request correction to your Personal Information to ensure its accuracy and completeness.
            A request must be in writing and provide sufficient detail to identify your Personal
            Information and the correction being sought.
          </p>
          <p>
            We are committed to ensuring the security of your Personal Information and may use
            passwords, encryption, firewalls, restricted employee access or other methods, in our
            discretion. We will use appropriate security measures when destroying your Personal
            Information such as shredding documents or deleting electronically stored information,
            in our discretion.
          </p>
          <h2>Log Data</h2>
          <p>
            We may also collect information that your browser sends whenever you visit our Service
            or when you access the Service by or through a mobile device ('Log Data').
          </p>
          <p>
            This Log Data may include information such as your computer's Internet Protocol ('IP')
            address, browser type, browser version, the pages of our Service that you visit, the
            time and date of your visit, the time spent on those pages and other statistics.
          </p>
          <p>
            When you access the Service by or through a mobile device, this Log Data may include
            information such as the type of mobile device you use, your mobile device unique ID, the
            IP address of your mobile device, your mobile operating system, the type of mobile
            Internet browser you use and other statistics.
          </p>
          <p>
            In addition, we may use third-party services such as Google Analytics that collect,
            monitor and analyze this type of information in order to increase our Service's
            functionality. These third-party service providers have their own privacy policies
            addressing how they use such information.
          </p>
          <h2>Cookies</h2>
          <p>
            Cookies are files with small amount of data, which may include an anonymous unique
            identifier. Cookies are sent to your browser from a website and stored on your
            computer's hard drive.
          </p>
          <p>
            We use 'cookies' to collect information. You can instruct your browser to refuse all
            cookies or to indicate when a cookie is being sent. However, if you do not accept
            cookies, you may not be able to use some portions of our Service. If you do not instruct
            your browser to refuse all cookies or to indicate when a cookie is being sent, your
            consent to our use of your Personal Information may be implied.
          </p>
          <h2>Service Providers</h2>
          <p>
            We may employ third-party companies and individuals to facilitate our Service, to
            provide the Service on our behalf, to perform Service-related services or to assist us
            in analyzing how our Service is used.
          </p>
          <p>
            These third parties have access to your Personal Information only to perform these tasks
            on our behalf and are obligated not to disclose or use it for any other purpose.
          </p>
          <h2>Communications</h2>
          <p>
            We may use your Personal Information to contact you on opt in newsletters, marketing or
            promotional materials and other information that may be of interest to you. You may opt
            out of receiving any, or all, of these communications from us by following the
            unsubscribe link or instructions provided in any email we send.
          </p>
          <h2>Compliance With Laws</h2>
          <p>
            As set out above, and for clarity, we will disclose your Personal Information where
            required to do so by law or subpoena or if we believe that such action is necessary to
            comply with the law and the reasonable requests of law enforcement or to protect the
            security or integrity of our Service.
          </p>
          <h2>Security</h2>
          <p>
            The security of your Personal Information is important to us, but remember that no
            method of transmission over the Internet, or method of electronic storage is 100%
            secure. While we strive to use commercially acceptable means to protect your Personal
            Information, we cannot guarantee its absolute security.
          </p>
          <h2>International Transfer</h2>
          <p>
            Your information, including Personal Information, may be transferred to — and maintained
            on — computers located outside of your province, country or other governmental
            jurisdiction where the data protection laws may differ than those from your
            jurisdiction.
          </p>
          <p>
            If you are located outside Canada and choose to provide information to us, please note
            that we transfer the information, including Personal Information, to Canada and process
            it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of such information
            represents your agreement to that transfer.
          </p>
          <h2>Links To Other Sites</h2>
          <p>
            Our Service may contain links to other sites that are not operated by us. If you click
            on a third-party link, you will be directed to that third-party's site. We strongly
            advise you to review the Privacy Policy of every site you visit.
          </p>
          <p>
            We have no control over, and assume no responsibility for the content, privacy policies
            or practices of any third-party sites or services.
          </p>
          <h2>Children's Privacy</h2>
          <p>Our Service does not address anyone under the age of 18 ('Minor').</p>
          <p>
            We do not knowingly collect personally identifiable information from Minors. If you are
            a parent or guardian and you are aware that your Minor has provided us with Personal
            Information, please contact us. If we become aware that we have collected Personal
            Information from Minors without verification of parental consent, we take steps to
            remove that information from our servers.
          </p>
          <h2>Changes To This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by
            posting the new Privacy Policy on this page and via e-mail if you choose to receive
            them.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.
          </p>
          <p>
            If we make any material changes to this Privacy Policy, we will notify you either
            through the email address you have provided or by placing a prominent notice on our
            website.
          </p>
          <h2>Contact Our Privacy Officer</h2>
          <p>
            If you have any questions about this Privacy Policy and how we handle your information,
            or to seek to exercise your rights concerning the personal information we hold about
            you, please contact us by e-mail at angle@designcode.io or by mail on the address: 831
            Anne-Hebert
          </p>
          <p>Terrebonne, Quebec, Canada</p>
          <p>J6Y0L1</p>
          <p>
            For the purposes of the GDPR, our Privacy Officer is also our Data Protection Officer
            (DPO).
          </p>
          <p>
            If we do not resolve your issue to your satisfaction and you are in the European Union,
            you can choose to lodge a complaint with your local Data Protection Authority (DPA). The
            list of DPAs is at{" "}
            <a
              href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
              aria-label="Privacy"
            >
              http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm
            </a>
            .
          </p>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default Privacy

export const query = graphql`
  query PrivacyQuery {
    contentfulMetadata(slug: { eq: "privacy" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 752px;
  margin: 0 auto;
  padding: 104px 20px 0px;
  box-sizing: border-box;
`

const Content = styled.div`
  position: relative;
  max-width: 800px;
  margin: 100px auto;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 17px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }

  img,
  video {
    margin: 20px 0;
    width: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }

  iframe {
    margin: 20px 0;
    width: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }

  h2 {
    padding: 20px 0 0;
    font-size: 32px;
    line-height: 120%;
  }

  h3 {
    padding: 20px 0 0;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.7;
  }

  h2,
  h3 {
    margin-top: 50px;
  }

  p {
    text-align: left;
    color: rgba(0, 0, 0, 0.8);

    @media (prefers-color-scheme: dark) {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  a {
    text-decoration: none;
    font-weight: 600;
    color: ${themes.light.primary};

    @media (prefers-color-scheme: dark) {
      color: ${themes.dark.secondary};
    }
  }

  blockquote {
    padding: 0 16px 0 50px;
    background-repeat: no-repeat;
    background-position: 14px 2px;
    p {
      font-size: 28px;
      font-weight: 500;
      line-height: 140%;
    }
  }

  pre {
    border-radius: 16px;
  }

  code {
    font-size: 17px;
    font-family: "SF Mono", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif;
    font-weight: 500;
  }

  ol {
    padding: 0;
  }

  @media (max-width: 740px) {
    font-size: 18px;
    padding: 0 30px;

    .Content {
      margin: 50px 20px;
    }
    p {
      font-size: 18px;
    }
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 20px;
    }
    blockquote {
      margin: 0;
      p {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 414px) {
    padding: 0 20px;
    code {
      font-size: 15px;
    }
    blockquote {
      margin: 0;
      p {
        font-size: 17px;
      }
    }
  }
`
